<template>
    <div>
       
    </div>
</template>


<script>
    export default {
        created() {
            this.$root.$data.store.logout();
        }

    }
</script>
